import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Geometry } from 'geojson';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheService } from 'src/app/core/services/cache.service';
import { MasterData } from 'src/app/shared/model/master-data/master-data.model';
import { IOperationalProcessContainer } from 'src/app/shared/model/order/job.interface';
import { ModeOfTransport, ModeOfTransportType } from 'src/app/shared/model/transport-mode.const';
import { MdmDataService } from 'src/app/shared/services/mdm-data.service';
import { DaService } from 'src/app/vessel-call/model/da-service.model';
import { IInvoiceDetail } from 'src/app/vessel-call/model/interfaces/invoice-detail.interface';
import { IQuotationServiceDetail } from 'src/app/vessel-call/model/interfaces/quotation.interface';
import { Vessel } from 'src/app/vessel-call/model/order/vessel-call/vessel.model';
import { ServiceProgress } from 'src/app/vessel-call/model/service-progress.model';
import { MdmVesselDto } from '../model/mdm-response.dto';
import { IGacService } from '../model/order/order.interface';
import { IPOShipmentDto } from '../model/purchase-order-tracking.dto';
import { SavingsType } from '../model/savings-type.enum';
import { ShipmentDetails, ShipmentLocation } from '../model/shipment/shipment-details';
import { ShipmentDetailsDto } from '../model/shipment/shipment-details.dto';
import { ShipmentEventDto } from '../model/shipment/shipment-event.dto';
import { ShipmentsIndexDto } from '../model/shipment/shipment-list.dto';
import { ShipmentsIndex } from '../model/shipment/shipments-index';
import { Constants } from './../../core/services/constants.service';
import { HelperService } from './../../core/services/helper.service';
import { ShipmentTrail } from './../../shared-logistics/model/shipment-trail.model';
import { WarehouseItem } from './../../shared-logistics/model/warehouse-item.model';
import { VesselCompare } from './../../vessel-call/model/order/vessel-call/vessel-compare.model';
import { PrtEvent } from './../components/events/prt-event.model';
import { IGeometryDto } from './../model/mdm-response.dto';
import { PartyType } from './../model/party-type.enum';
import { IPoWarehousePackageDto } from './../model/purchase-order-tracking.dto';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MapperService {

  constructor(@Inject(LOCALE_ID) private locale: string,
    private cacheService: CacheService,
    private mdmDataService: MdmDataService,
    private helperService: HelperService) { }

  public static mapMdmVesselToModel(dto: MdmVesselDto): Vessel {
    const vesselType = new MasterData(dto?.VesselType?.Code, dto?.VesselType?.Name);
    const vessel = new Vessel(dto.rowidObject.trim(), dto.VesselName, vesselType, dto.IMONumber?.toString());
    vessel.beam = +(dto.BeamExtreme ?? 0);
    vessel.dwt = +(dto.Dwt ?? 0);
    vessel.loa = +(dto.Loa ?? 0);
    vessel.grt = +(dto.GrossTonnage ?? 0);
    vessel.nrt = +(dto.NetTonnage ?? 0);
    vessel.maxDraft = +(dto.MaxDraft ?? 0);
    vessel.suezNRT = +(dto.SuezNetTonnage ?? 0);
    vessel.iceVesselClass = dto.ICEVesselClass ? new MasterData(dto.ICEVesselClass?.Code, dto.ICEVesselClass?.Name) : undefined;
    vessel.imageUrl = dto.ImageUrl;
    return vessel;
  }

  public mapShipmentListDto(dto: ShipmentsIndexDto): ShipmentsIndex {
    const response = new ShipmentsIndex();

    response.id = dto.id;
    response.transportationMode = dto.transportationMode;
    response.modeOfTransport = dto.modeOfTransport;
    response.freightType = dto.freightType;
    response.shipmentType = dto.shipmentType;
    response.bl = this.getBlFromModeOfTransport(dto);
    response.carrierName = dto.carrierName;
    response.origin = ShipmentLocation.createFromDto(dto.origin);
    response.destination = ShipmentLocation.createFromDto(dto.destination);
    response.jobGroupFk = dto.jobGroupFk;
    response.jobNumber = dto.jobNumber;
    response.customerReferenceNo = dto.customerReferenceNo;
    response.shipper = dto.shipper;
    response.consignee = dto.consignee;
    response.latestEventName = dto.latestEvent?.name;
    response.latestEventDate = dto.latestEvent?.eventDateTime;

    //temp
    response.eta = dto.eta;
    response.etd = dto.etd;
    response.totalVolume = dto.dimensions?.totalVolume;
    response.totalVolumeUom$ = this.cacheService.getUomLookup()
      .pipe(map(uoms => uoms.find(u => u.code === dto.dimensions?.totalVolumeUOM) || { symbol: '', name: dto.dimensions?.totalVolumeUOM }),
        map(u => u?.symbol || u?.name));
    response.totalWeight = dto.dimensions?.totalWeight;
    response.totalWeightUom$ = this.cacheService.getUomLookup()
      .pipe(map(uoms => uoms.find(u => u.code === dto.dimensions?.totalWeightUOM) || { symbol: '', name: dto.dimensions?.totalWeightUOM }),
        map(u => u?.symbol || u?.name));

    response.progress = DateHelperService.getEtaProgress(dto.eta, dto.etd);

    return response;
  }

  public getBlFromModeOfTransport(shipmentDto: { modeOfTransport: ModeOfTransportType | null, blNumber: string, awb: string, wayBillNumber: string; }): string {
    switch (shipmentDto.modeOfTransport) {
      case ModeOfTransport.Air.type:
        return shipmentDto.awb;
      case ModeOfTransport.Sea.type:
        return shipmentDto.blNumber;
      case ModeOfTransport.Land.type:
        return shipmentDto.wayBillNumber;
      default:
        return '';
    }
  }

  public mapShipmentDetailsDto(dto: ShipmentDetailsDto): ShipmentDetails {
    const response = new ShipmentDetails();

    response.opId = dto.opId;
    response.jobNumber = dto.jobNumber;
    response.customerReferenceNo = dto.customerReferenceNo;
    response.transportationMode = dto.transportationMode;
    response.modeOfTransport = dto.modeOfTransport;
    response.bl = this.getBlFromModeOfTransport(dto);
    response.carrierName = dto.carrierName;
    response.origin = ShipmentLocation.createFromDto(dto.origin);
    response.destination = ShipmentLocation.createFromDto(dto.destination);
    response.originAgentName = dto.originAgentName;

    response.destinationAgentName = dto.destinationAgentName;
    response.eta = dto.eta;
    response.etd = dto.etd;
    response.ata = dto.ata;
    response.atd = dto.atd;
    response.vessel = dto.vessel;
    response.flightNo = dto.flightNo;
    response.bookingType = dto.bookingType;
    response.shipmentType = dto.shipmentType || 'General';
    response.freightType = dto.freightType;
    response.modeType = dto.modeType;

    response.truckNo = dto.truckNo;
    response.transporterName = dto.transporterName;


    dto.parties.forEach(p => {
      let partyName = p.commonName || p.legalName;
      switch (p.partyType) {
        case PartyType.Customer: {
          response.customer = partyName;
          break;
        }
        case PartyType.Shipper: {
          response.shipper = partyName;
          break;
        }
        case PartyType.Consignee: {
          response.consignee = partyName;
          break;
        }
      }
    });

    response.containers = dto.containers.map(c => this.mapShipmentContainerDto(c));
    response.poList = dto.poList;
    response.packages = dto.packages.map(p => {
      p.volumeUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === p.volumeUomCode) || { symbol: '', name: p.volumeUomName }),
          map(u => u?.symbol || u?.name));
      p.weightUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === p.weightUomCode) || { symbol: '', name: p.weightUomName }),
          map(u => u?.symbol || u?.name));
      return p;
    });
    response.shipmentDetails = dto.shipmentDetails ? dto.shipmentDetails.map(sd => {
      sd.volumeUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === sd.totalVolumeUomCode) || { symbol: '', name: sd.totalVolumeUomName }),
          map(u => u?.symbol || u?.name));
      sd.weightUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === sd.totalWeightUomCode) || { symbol: '', name: sd.totalWeightUomName }),
          map(u => u?.symbol || u?.name));
      return sd;
    }) : null;
    response.events = dto.events.map(e => MapperService.mapShipmentEventDto(e));
    response.documents = dto.documents;


    response.progress = DateHelperService.getEtaProgress(dto.eta, dto.etd) || 0;

    //get event location names
    this.populateEventLocations(response.events);

    return response;
  }

  private populateEventLocations(events: PrtEvent[]) {
    if (events?.length) {
      let eventsMissingLocationName = events.filter(e => !e.location && e.locationCode);
      let loadingLocation$ = new BehaviorSubject(true);
      eventsMissingLocationName.forEach(e => {
        e.loadingLocation$ = loadingLocation$.asObservable();
      });
      let uniqueLocationCodes = [...new Set(eventsMissingLocationName.map(e => e.locationCode!))];

      this.mdmDataService
        .getLocations(
          uniqueLocationCodes.filter(x => x.length > 3),
          uniqueLocationCodes.filter(x => x.length === 3))
        .subscribe(locations => {
          loadingLocation$.next(false);
          locations.forEach(l => {
            let eventsToUpdate = eventsMissingLocationName.filter(e => e.locationCode == l.Code || e.locationCode == l.IATA);
            if (eventsToUpdate) {
              eventsToUpdate.forEach(e => e.location = l.Name);
            }
          });
        }, () => {
          loadingLocation$.next(false);
        });
    }
  }

  public mapGeometryDto<T extends IGeometryDto>(source: T): T {
    if (source.Geometry) {
      // replace all (( with [ and all )) with ]
      source.Geometry = source.Geometry.replace(/\(\(/g, '[').replace(/\)\)/g, ']');
      // replace all remaining ( with [ and all ) with ]
      source.Geometry = source.Geometry.replace(/\(/g, '[').replace(/\)/g, ']');
      // detect two decimal numbers without comma between them and add the comma and surround with []
      source.Geometry = source.Geometry.replace(/(\d+\.\d+)\s+(\d+\.\d+)/g, '[$1,$2]');

    }
    let geometryParsed;
    try {
      geometryParsed = source.Geometry ? JSON.parse(source.Geometry) : null;
    } catch (error) { }
    if (geometryParsed?.[0]?.[0]?.[0]?.[0]) {
      geometryParsed = {
        type: 'MultiPolygon',
        coordinates: geometryParsed
      } as Geometry;
    }
    else if (geometryParsed?.[0]?.[0]?.[0]) {
      geometryParsed = {
        type: 'Polygon',
        coordinates: geometryParsed
      } as Geometry;
    }
    else {
      geometryParsed = undefined;
    }
    return {
      ...source,
      GeometryParsed: geometryParsed
    };
  }

  public mapShipmentContainerDto(dto: IOperationalProcessContainer): IOperationalProcessContainer {
    const weightUom$ = this.cacheService.getUomLookup()
      .pipe(map(uoms => uoms.find(u => u.code === dto.totalWeightUomCode) || { symbol: dto.totalWeightUomCode, name: '' }),
        map(u => u?.symbol || u?.name));
    const volumeUom$ = this.cacheService.getUomLookup()
      .pipe(map(uoms => uoms.find(u => u.code === dto.totalVolumeUomCode) || { symbol: '', name: dto.totalVolumeUomName }),
        map(u => u?.symbol || u?.name));
    const response = { ...dto, weightUom$, volumeUom$ } as IOperationalProcessContainer;
    return response;
  }

  // public mapShipmentPackageDto(dto: ShipmentPackageDto): ShipmentPackage {

  //   const response = {
  //     ...dto,
  //     weightUom$: this.cacheService.getUomLookup().pipe(
  //       map(uoms => uoms.find(uom => uom.code === dto.weightUomCode) || { symbol: dto.weightUomCode, name: '' }), map(uom => uom?.symbol || uom?.name)),
  //     volumeUom$: this.cacheService.getUomLookup().pipe(
  //       map(uoms => uoms.find(uom => uom.code === dto.volumeUomCode) || { symbol: dto.volumeUomCode, name: '' }), map(uom => uom?.symbol || uom?.name)),
  //   } as ShipmentPackage;
  //   return response;
  // }

  public static mapShipmentEventDto(dto: ShipmentEventDto): PrtEvent {
    const response = new PrtEvent();

    response.name = dto.name;
    response.location = dto.locationName;
    response.locationCode = dto.locationCode;
    response.date = dto.eventDateTime;


    return response;
  }

  public static mapServiceLineItemToServiceProgress(service: IGacService, vc: any): ServiceProgress {
    const hasCharges: boolean = service.charges?.length > 0;
    const serviceProgress: ServiceProgress = new ServiceProgress(
      service.name,
      hasCharges ? service.charges[0].priceCurrency : service.currency,

      hasCharges ? service.charges
        .map((charge: any) => charge.unitPrice * charge.quantity)
        .reduce((totalAmount: number, currentAmount: number) => totalAmount + currentAmount) : (service.unitPrice || 0) * (service.quantity || 0),
      // service.isCompleted,
      !!vc?.atd,
      service.exchangeRate ?? 1,
      vc?.ata || vc?.eta || null,
      vc?.atd || vc?.etd || null
    );
    return serviceProgress;
  }

  public static mapInvoiceLineItemToServiceProgress(service: IInvoiceDetail, vc: any): ServiceProgress {
    const serviceProgress: ServiceProgress = new ServiceProgress(
      service.name,
      service.billingCurrencyName,
      service.billedUnitPrice * service.quantity + (service.billedTax || 0),
      // service.isCompleted,
      !!vc?.atd,
      service.billedExchangeRate ?? 1,
      vc?.ata || vc?.eta || null,
      vc?.atd || vc?.etd || null
    );
    return serviceProgress;
  }

  public static mapQuotationServiceToServiceProgress(service: IQuotationServiceDetail, vc: any): ServiceProgress {
    const amount = (service.unitPrice || 0) * (service.quantity || 0) + (service.priceTax || 0);
    const exchangeRate = service.priceExRate ?? 1;
    const isCompleted = !!vc?.atd;
    const startDate = vc?.ata || vc?.eta || null;
    const endDate = vc?.atd || vc?.etd || null;
  
    return new ServiceProgress(
      service.name,
      service.priceCurrencyName || service.priceCurrencyCode,
      amount,
      isCompleted,
      exchangeRate,
      startDate,
      endDate
    );
  }

  //pda mapping
  public static mapQuotationServiceDetailToDaService(s: IQuotationServiceDetail): DaService {
    let amountLocalExVat: number = s.unitPrice * s.quantity;
    let amountLocal: number = amountLocalExVat + (s.priceTax || 0);
    let service = new DaService(
      s.code,
      s.name,
      s.priceExRate,
      s.priceCurrencyName,
      s.unitPrice,
      s.unitPrice / s.priceExRate,
      s.quantity,
      amountLocalExVat,
      amountLocalExVat / s.priceExRate,
      amountLocal,
      amountLocal / s.priceExRate,
      s.taxTypeName,
      s.taxRate,
      s.priceTax || 0,
      (s.priceTax || 0) / s.priceExRate,
      s.supplierName,
      s.internalRemarks,
      s.hubRemarks || null,
      s.principalRejectedRemarks || null,
      s.rebillableRemarks || null,
      s.costSavingsRemarks || null,
      undefined,
      s.savingsAmount || 0,
      (s.savingsAmount || 0) / s.priceExRate,
      s.isRebillable || false,
      s.savingsTypeCode,
      s.savingsTypeCode == SavingsType.NoSavings ? '' : s.savingsTypeName,
      undefined,
      undefined,
      undefined,
      s.version,
      s.payToPartyName,
      s.billingPartyName,
      s.costAllocatedToPartyName,
      s.approvalStatusCode,
      s.approvalStatusName
    );
    service.serviceGroup = s.serviceGroup ?? { code: '', name: service.name };
    service.modifiedAt = s.modifiedAt; //temporary fix for remarks date, replace when remarks array has been added for pda
    service.isWorldscale = s.isWorldscale;
    service.savingsPercentage = s.savingsAmount ? s.savingsAmount / (s.savingsAmount + amountLocalExVat) : undefined;

    return service;
  }

  //spda mapping
  public static mapGacServiceToDaService(s: IGacService): DaService {
    let amountLocalExVat: number = ((s.unitPrice || 0) * (s.quantity || 0));
    let amountLocal: number = amountLocalExVat + (s.taxAmount || 0);

    let service = new DaService(
      s.code,
      s.name,
      s.exchangeRate,
      s.currency,
      s.unitPrice,
      s.exchangeRate ? ((s.unitPrice || 0) / s.exchangeRate) : 0,
      s.quantity,
      amountLocalExVat,
      s.exchangeRate ? (amountLocalExVat / s.exchangeRate) : 0,
      amountLocal,
      s.exchangeRate ? (amountLocal / s.exchangeRate) : 0,
      s.taxName,
      s.taxRate,
      s.taxAmount || 0,
      s.exchangeRate ? ((s.taxAmount || 0) / s.exchangeRate) : null,
      s.supplier?.name,
      s.remarks,
      s.hubRemarks || null,
      s.principalRejectedRemarks || null,
      s.rebillableRemarks || null,
      s.costSavingsRemarks || null,
      undefined,
      s.savingsAmount || 0,
      s.exchangeRate ? ((s.savingsAmount || 0) / s.exchangeRate) : 0,
      s.isRebillable || false,
      s.savingsTypeCode,
      s.savingsTypeCode == SavingsType.NoSavings ? '' : s.savingsTypeName,
      undefined,
      undefined,
      undefined,
      s.version,
      s.payToPartyName,
      s.billingPartyName,
      s.costAllocatedToPartyName,
      s.approvalStatusCode,
      s.approvalStatusName,
      undefined,
      s.costSummaryRemarks,
      undefined,
      undefined,
    );
    service.serviceGroup = s.serviceGroup ?? { code: '', name: service.name };
    service.isWorldscale = s.isWorldscale;
    service.savingsPercentage = s.savingsAmount ? s.savingsAmount / (s.savingsAmount + amountLocalExVat) : undefined;
    return service;
  }

  //fda mapping
  public static mapInvoiceDetailToDaService(s: IInvoiceDetail): DaService {
    let amountLocalExVat: number = s.billedUnitPrice * s.quantity;
    let amountLocal: number = amountLocalExVat + (s.billedTax || 0);
    let service = new DaService(
      s.code,
      s.name,
      s.billedExchangeRate,
      s.billingCurrencyName,
      s.billedUnitPrice,
      s.billedExchangeRate ? s.billedUnitPrice / s.billedExchangeRate : 0,
      s.quantity,
      amountLocalExVat,
      s.billedExchangeRate ? amountLocalExVat / s.billedExchangeRate : 0,
      amountLocal,
      s.billedExchangeRate ? amountLocal / s.billedExchangeRate : 0,
      s.billedTaxName,
      s.taxRate,
      s.billedTax || 0,
      s.billedExchangeRate ? (s.billedTax || 0) / s.billedExchangeRate : 0,
      s.supplierName,
      s.internalRemarks,
      s.hubRemarks || null,
      s.principalRejectedRemarks || null,
      s.rebillableRemarks || null,
      s.costSavingsRemarks || null,
      undefined,
      s.savingsAmount || 0,
      s.billedExchangeRate ? ((s.savingsAmount || 0) / s.billedExchangeRate) : 0,
      s.isRebillable || false,
      s.savingsTypeCode,
      s.savingsTypeCode == SavingsType.NoSavings ? '' : s.savingsTypeName,
      undefined,
      undefined,
      undefined,
      s.version,
      s.payToPartyName,
      s.billingParty,
      s.costAllocatedToPartyName,
      s.approvalStatusCode,
      s.approvalStatusName,
      s.invoiceRemarks,
      undefined,
      undefined,
      s.invoiceDetailId
    );

    service.voucherNumber = s.voucherNumber;
    service.serviceGroup = s.serviceGroup ?? { code: '', name: service.name };
    service.isWorldscale = s.isWorldscale;
    service.savingsPercentage = s.savingsAmount ? s.savingsAmount / (s.savingsAmount + amountLocalExVat) : undefined;

    return service;
  }

  public mapPoShipmentDtoToShipmentTrail(dto: IPOShipmentDto): ShipmentTrail {
    let shipmentTrail = new ShipmentTrail(
      dto.jobOperationalProcessId,
      dto.modeOfTransport,
      dto.transportationMode,
      dto.freightType,
      ShipmentLocation.createFromDto(dto.origin),
      ShipmentLocation.createFromDto(dto.destination),
      this.getBlFromModeOfTransport(dto),
      DateHelperService.getEtaProgress(dto.eta, dto.etd),
      dto.events.map(MapperService.mapShipmentEventDto),
      dto.packageIds,
      dto.jobNumber,
      dto.isActive
    );

    this.populateEventLocations(shipmentTrail.events);

    return shipmentTrail;
  }

  mapPoWarehousePackageDtoToWarehouseItems(dto: IPoWarehousePackageDto): WarehouseItem[] {
    return dto.warehousePackages.map((wp) => {
      return {
        mrNumber: wp.mrNumber || dto.processReferenceNumber,
        packageCode: wp.packageCode,
        noOfPackage: 1, // todo
        receivedQuantity: wp.totalQuantity,
        arrivedAt: wp.arrivedAt ? formatDate(wp.arrivedAt, Constants.dateTimeFormat, this.locale) : 'N/A',
        receivedAt: wp.receivedAt ? formatDate(wp.receivedAt, Constants.dateTimeFormat, this.locale) : 'N/A',
        pickedAt: wp.pickedAt ? formatDate(wp.pickedAt, Constants.dateTimeFormat, this.locale) : 'N/A',
        packedAt: wp.packedAt ? formatDate(wp.packedAt, Constants.dateTimeFormat, this.locale) : 'N/A',
        dispatchedAt: wp.dispatchedAt ? formatDate(wp.dispatchedAt, Constants.dateTimeFormat, this.locale) : 'N/A',
        officeCode: wp.officeCode,
        bookedAt: wp.loadPlannedAt ? formatDate(wp.loadPlannedAt, Constants.dateTimeFormat, this.locale) : 'N/A',
        // isShipped: null
        jobOperationalProcessId: dto.jobOperationalProcessId,
        guid: this.helperService.getNewGuid(),
        operationalProcessType: dto.jobOperationalProcessType,
        source: dto.source,
        grnExternalDocumentId: dto.grnExternalDocumentId
      } as WarehouseItem;
    });
  }

  public static mapMdmVesselToSuitableModel(dto: MdmVesselDto): VesselCompare {
    const vesselType = new MasterData(dto?.VesselType?.Code, dto?.VesselType?.Name);
    let vesselCompare = new VesselCompare(
      dto.rowidObject.trim(),
      dto.VesselName,
      vesselType,
      dto.IMONumber?.toString(),
      Number(dto.BeamExtreme),
      Number(dto.MaxDraft),
      Number(dto.Dwt),
      Number(dto.GrossTonnage),
      Number(dto.Loa),
      dto.IHSVesselType,
      dto.ImageUrl
    );

    return vesselCompare;
  }
}
